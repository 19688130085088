/* @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@font-face {
  font-family: eng;
   src:url('assets/font/inter.ttf');  
}

@font-face {
  font-family: alltext;
   src:url('assets/font/all.TTF');  
}

#root{
margin-left: auto;
margin-right: auto;
}



body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

background-color: #181818;
color:#FFFFFF;
}


*:focus {
  outline: none;
}

.specFont{
  font-family: 'Zen Dots', sans-serif;
}
.opacity-54{
  opacity: 0.54;
}

.ratio-1\/1 {
  width: 100%;
  padding-top: 100%; /*  Aspect Ratio */
  position: relative;
}

.ratio-16\/9 {
  width: 100%;
  padding-top: 56.25%; /*  Aspect Ratio */
  position: relative;
}

.ratio-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}



.bg1 {
  position: relative;
}
.bg1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}

@media screen and (min-width: 1280px) {

}

.appbg {
  position: relative;
}
.appbg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/appbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.25;
  filter: brightness(0.95); */
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}

.menubg {
  position: relative;
}
.menubg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
  background-image: url("assets/appbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.25;
  filter: brightness(0.95); */
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}

.social1 {
  position: relative;
}
.social1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/social1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 50%; 
  background-position-x: 50%; 
}

.social2 {
  position: relative;
}
.social2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/social2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 50%; 
  background-position-x: 50%; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appWidth{
  width: 90%;
  max-width: 1470px;
  margin-left: auto;
  margin-right: auto;
}

.gradiBtn{
  background: linear-gradient(90deg, #F54097 0%, #EF369B 7.69%, #E92C9F 15.38%, #E122A4 23.08%, #D918A9 30.77%, #D00DAE 38.46%, #C603B3 46.15%, #BA00B9 53.85%, #AD01BE 61.54%, #9F07C4 69.23%, #8E10C9 76.92%, #7A18CF 84.62%, #6220D4 92.31%, #3F26D9 100%), linear-gradient(87.21deg, #F54097 0%, #3F26D9 100%);
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

.blingBtn{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #EB6E54;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #EB6E54;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 10px 20px 0px rgba(225, 69, 33, 0.28);

  transition: all .45s;
    transition-property: all;
    transition-duration: 0.45s;
    transition-timing-function: ease;
    transition-delay: 0s;
}
.blingBtn:hover{
  background-color: rgb(224, 93, 66);
}

.boxshadow1{
  box-shadow: 0px 10px 20px 0px rgba(221, 50, 12, 0.669);
}
.anim{
  transition: all 0.4s ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

